import * as React from 'react'
import { Button } from 'react-foundation'
import { HatchingIcon } from '../igniter/Icons'
import { TrialActivationConfirmationModal } from './components/TrialActivationConfirmationModal'

interface IProps {
  planName: string
  planPrice: number
  installmentCount: number
  buttonClassName: string | null
  hatching?: boolean
}

interface IState {
  showModal: boolean
}

export class TrialActivationButton extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    buttonClassName: null,
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  public handleOpen = () => {
    this.setState({
      showModal: true,
    })
  }

  public handleClose = () => {
    this.setState({
      showModal: false,
    })
  }

  public render() {
    const { showModal } = this.state
    const {
      planName,
      planPrice,
      installmentCount,
      buttonClassName,
      hatching,
    } = this.props

    if (planName === 'Worship Together Kids All-Access') {
      return <></>
    } else {
      return (
        <React.Fragment>
          <Button
            key="confirmation-modal-trigger"
            className={`activate-now ${buttonClassName} ${
              hatching ? 'with-hatching' : ''
            }`}
            onClick={this.handleOpen}
          >
            Activate Plan Now
            {hatching && (
              <div className={`hatching ${hatching ? 'primary' : hatching}`}>
                <HatchingIcon />
              </div>
            )}
          </Button>
          <TrialActivationConfirmationModal
            key="trial-activation-confirmation-modal"
            planName={planName}
            planPrice={planPrice}
            installmentCount={installmentCount}
            isOpen={showModal}
            handleClose={this.handleClose}
          />
        </React.Fragment>
      )
    }
  }
}
