var React = require('react');

function FacebookLogo (props) {
    return React.createElement("svg",props,React.createElement("path",{"className":"primary-fill","d":"M0.258065 8.5216H2.01192V15.7419C2.01192 15.8845 2.12743 16 2.26999 16H5.24372C5.38627 16 5.50178 15.8845 5.50178 15.7419V8.55561H7.51799C7.64908 8.55561 7.75938 8.45724 7.77435 8.32702L8.08057 5.66885C8.08898 5.59572 8.06581 5.52248 8.01688 5.46761C7.9679 5.4127 7.89781 5.38126 7.82426 5.38126H5.50188V3.71499C5.50188 3.2127 5.77234 2.95799 6.30581 2.95799C6.38183 2.95799 7.82426 2.95799 7.82426 2.95799C7.96681 2.95799 8.08232 2.84243 8.08232 2.69992V0.259974C8.08232 0.117419 7.96681 0.00190968 7.82426 0.00190968H5.73161C5.71685 0.0011871 5.68408 0 5.63577 0C5.27267 0 4.01058 0.0712774 3.01363 0.988439C1.90901 2.0048 2.06255 3.22173 2.09925 3.43272V5.38121H0.258065C0.11551 5.38121 0 5.49672 0 5.63928V8.26348C0 8.40604 0.11551 8.5216 0.258065 8.5216Z"}));
}

FacebookLogo.defaultProps = {"width":"9","height":"16","viewBox":"0 0 9 16","fill":"none"};

module.exports = FacebookLogo;

FacebookLogo.default = FacebookLogo;
